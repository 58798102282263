<template>
  <section id="partnership">
    <div class="container overflow-hidden">
      <div class="row justify-content-between align-items-center mt-5">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          <p class="h2 text-main fw-bolder">
            Sən də tərəfdaş ol
          </p>
          <div class="partnership-content mt-3">
            Sizə öz işinizi açmaq və ya aparıcı sığorta şirkətlərinin sığorta məhsullarını sataraq müştərilərinizə və ya əməkdaşlarınıza təqdim olunan xidmətləri genişləndirmək üçün unikal bir fürsət 
            təqdim edirik.
          </div>
          <div class="mt-3">
            <router-link
                to="/partnership"
                class="
                btn 
                btn-default btn-hover
                d-inline-block 
                mt-2 
                text-decoration-none
                partnership-content-btn
                "
              >
              Əməkdaşlıq et
              </router-link>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center ">
          <div class="partner-img">
            <img src="@/assets/images/bepartner.png" class="me-md-auto ms-md-auto" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>