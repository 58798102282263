<template>
  <div>
    <banner />

    <advantages/>

    <partners-carusel />

    <how-to-get />

    <partnership />

    <app-section />

  </div>
</template>

<script>
import Banner from "../shared/Banner.vue";
import HowToGet from "../shared/HowToGet.vue";
import AppSection from "../shared/AppSection.vue";
import Partnership from "../shared/Partnership.vue";
import PartnersCarusel from "../shared/PartnersCarusel.vue";
import Advantages from "../shared/Advantages.vue";

export default {
  components: {
    Banner,
    Advantages,
    PartnersCarusel,
    HowToGet,
    Partnership,
    AppSection,
  }
};
</script>