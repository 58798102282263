<template>
  <section id="advantages" class="pb-0">
    <div class="advantages-section">
      <div class="container p-6">
      <div class="row rounded advantages m-0">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
          <p class="h2 text-main fw-bolder text-center">
            Üstünlüklərimiz
          </p>
        </div>
        <div class="
              col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
              pb-5
              mt-4
              mx-auto
            ">
          <div class="row justify-content-around advantages-row">
            <div class="col-lg-4 col-xl-3 col-md-12 col-sm-12 col-xs-12 ">
              <div class="home-advantages-item">
                <div class="text-center mx-auto d-flex align-items-center">
                  <img src="@/assets/images/advantages-1.svg" class="mx-auto" />
                </div>
                <p class="h6 pt-3">
                  Fərqli sığorta məhsullarını birbaşa, rahat, heç yerə getmədən əldə edin.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-12 col-sm-12 col-xs-12">
              <div class="home-advantages-item">
                <div class="text-center mx-auto d-flex align-items-center">
                  <img src="@/assets/images/advantages-2.svg" class="mx-auto" />
                </div>
                <p class="h6 pt-3">
                  Bütün sığorta müqavilələrini elektron kabinetdən idarə edin.
                </p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-12 col-sm-12 col-xs-12">
              <div class="home-advantages-item">
                <div class="text-center mx-auto d-flex align-items-center">
                  <img src="@/assets/images/advantages-3.svg" class="mx-auto" />
                </div>
                <p class="h6 pt-3">
                  Sığortayla bağlı məsələlərdə kömək və məsləhət alın
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>