<template>
  <section id="how-to-get">
    <div class="container overflow-hidden position-relative">
      <img src="@/assets/images/message.svg" class="message-box" />
      <div class="row rounded">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <p class="h2 text-main fw-bolder text-center pt-2">
            Onlayn sığortanı necə <br /> əldə  etmək olar?
          </p>
        </div>
        <div class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                mt-5
                mx-auto
              ">
          <div class="row position-relative">
            <img src="@/assets/images/pattern.svg" class="ways-bg" />
            <div class="how-to-get-row">
              <div class="
                    col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                    how-to-get-item
                  ">
                <div class="text-center bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/query.svg" class="mx-auto" />
                </div>
                <p class="h6 how-to-get-item-text text-center pt-3">Sorğu</p>
                <p class="text-center font-size-default text-gray">
                  N/V və şəxsi məlumatları daxil edin. Məlumatların düzgün
                  daxil edilməsinə diqqət yetirin.
                </p>
              </div>
              <div class="
                    col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                    how-to-get-item
                  ">
                <div class="text-center bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/check.svg" class="mx-auto" />
                </div>
                <p class="h6 how-to-get-item-text text-center pt-3">
                  Şirkət seçimi
                </p>
                <p class="text-center font-size-default text-gray">
                  Təklif olunmuş sığorta şirkətlərini nəzərdən keçirin və
                  istədiyiniz sığorta şirkətini seçin.
                </p>
              </div>
              <div class="
                    col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                    how-to-get-item
                  ">
                <div class="text-center bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/pay.svg" class="mx-auto" />
                </div>
                <p class="h6 how-to-get-item-text text-center pt-3">Ödəniş</p>
                <p class="text-center font-size-default text-gray">
                  Nömrənizə gələn ödəniş kodu vasitəsilə ONLAYN ÖDƏ bölməsinə
                  daxil olmaqla və ya sizə rahat digər üsulla ödəyin.
                </p>
              </div>
              <div class="
                    col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                    how-to-get-item
                  ">
                <div class="text-center bg-white mx-auto d-flex align-items-center">
                  <img src="@/assets/images/insurance.svg" class="mx-auto" />
                </div>
                <p class="h6 how-to-get-item-text text-center pt-3">
                  Sığorta şəhadətnaməsi
                </p>
                <p class="text-center font-size-default text-gray">
                  Sığorta şəhadətnaməsi aktivləşdi, elektron nüsxə kabinetinizə
                  yükləndi. Xeyirli olsun!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>