<template>
  <div class="row  mb-1 ">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 init-form-col">
      <div class="form-group ">
        <label :class="{ 'full': form.countryId }" class="initial-label small init-label" for="country">
          Səfər edilən ölkə
        </label>
        <select id="country"  name="country" @click="getCountries" v-model="form.countryId"
        :class="{ 'has-value': form.countryId }"  class="form-select initial-form-select input-default font-size-default px-4 mt-2">
          <option  disabled selected :value="null">Səfər edilən ölkə</option>
          <option  v-for="c in form.country" :key="c.id" :value="c.id">{{ c.name }}</option>
        </select>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-1">
      <div class="form-group " >
        <label :class="{ 'full': form.startDate }" class="initial-label small mb-2" >
          Başlama tarixi
        </label>
        <input v-model="form.startDate" :min="minDate" type="date" :class="{ 'has-value': form.startDate }"  class="form-control input-default font-size-default"/>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-1">
      <div class="form-group " >
        <label :class="{ 'full': form.endDate }" class="initial-label small mb-2" >
          Bitmə tarixi
        </label>
        <input  v-model="form.endDate" :min="minEndDate"   type="date" :class="{ 'has-value': form.endDate }" class="form-control input-default font-size-default"/>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
      <button type="submit"
        class="btn-main btn-default btn-hover cursor-pointer d-inline-block  btn-cmptl-form init-btn position-relative"
        @click="next">
        Davam et
      </button>
    </div>
  </div>
</template>
  
<script>
import api from "@/api/travel.api";


export default {
  data: () => ({
    form: {
      countryId:0,
      startDate: "",
      endDate: "",
    },
    country: [],
    days:0
  }),
  computed: {
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    minEndDate() {
      const startDate = new Date(this.form.startDate);
      startDate.setDate(startDate.getDate() + 3);
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const day = String(startDate.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
  },
  methods: {
    next() {
      const start = new Date(this.form.startDate);
      const end = new Date(this.form.endDate);
      const diffInMilliseconds = Math.abs(end - start);
      const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
      this.days= diffInDays;
      this.$router.push({
        name: "ins-travel",
        query: { startDate:this.form.startDate, endDate:this.form.endDate, countryId:this.form.countryId, days:this.days   },
      });
    },
    getCountries() {
      api.getCountries().then((data) => {
        this.form.country = data;
      }).catch((error) => {
        this.emitter.emit("error", error);
        this.isLoading = false;
      });
    },
  },
};
</script>





<style>
.date-input {
  position: relative;
}

.date-input img {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>





