<template>
  <section id="app-mobile">
    <div class="">
      <div class="app-mobile   m-0">
       <div class="container">
        <div class="row justify-content-around  m-0">
          <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 app-mobile-col"
        >
          <div class="app-mobile-img">
            <img src="@/assets/images/iphone12.svg" class="mx-auto ps-0" />
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-5 my-auto "
        >
          <p class="h2 text-main fw-bolder align-items-md-center-app app-mobile-h2">
            <span class="text-blue">Mobins</span> tətbiqini yüklə
          </p>
          <div class="partnership-content mt-1 mb-3"> 
            Mobins tətbiqini yükləyərək istədiyin yerdən rahat və asan şəkildə sığortanı əldə edə bilərsən.
          </div>
          <div class="pb-3 align-items-md-center-app">
            <a
              href="https://apps.apple.com/az/app/mobins/id1570164544"
              target="_blank"
              class="
                btn-default btn-main
                d-inline-block
                text-decoration-none text-white
                me-2
                mt-1
              "
            >
              <div class="d-inline-block pr-2">
                <img
                  src="@/assets/images/appstore.svg"
                  class="align-baseline"
                />
              </div>
              <div class="d-inline-block ps-2 m-0">
                <p class="font-weight-bold p-0 m-0 fw-bold">App store</p>
                <p class="font-size-small p-0 m-0  app-mobile-text">
                  Yükləmək üçün kliklə
                </p>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=septem.mobins.mobins_mobile"
              target="_blank"
              class="
                btn-default btn-main
                d-inline-block
                text-decoration-none
                text-white
                mt-1
              "
            >
              <div class="d-inline-block pe-2">
                <img
                  src="@/assets/images/playstore.svg"
                  class="align-baseline"
                />
              </div>
              <div class="d-inline-block p-0 m-0">
                <p class="font-weight-bold p-0 m-0 fw-bold">Play store</p>
                <p class="font-size-small p-0 m-0 app-mobile-text">
                  Yükləmək üçün kliklə
                </p>
              </div>
            </a>
          </div>
        </div>
        </div>
       </div>
      </div>
    </div>
  </section>
</template>