<template>
  <div class="row  mb-1">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 mt-2 initial-form-input">
      <div class="form-group">
        <label :class="{ 'full': form.carNumberquery }" class="initial-label small mb-2" for="carNumberquery">
          Dövlət qeydiyyat nişanı
        </label>
        <input
          type="text"
          id="carNumberquery"
          name="carNumberquery"
          v-model="form.carNumberquery"
          class="form-control input-default font-size-default px-4"
          placeholder="90MB123"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 mt-2 initial-form-input">
      <div class="form-group">
        <label :class="{ 'full': form.certificateNumber }" class="initial-label small mb-2" for="certificateNumber">
          Avtomobil Şəhadətnamə Nömrəsi
        </label>
        <input
          id="certificateNumber"
          name="certificateNumber"
          type="text"
          v-model="form.certificateNumber"
          class="form-control input-default font-size-default px-4"
          placeholder="AD123456"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mt-3 initial-form-button ">
      <button
        type="submit"
        class="btn-main btn-default btn-hover cursor-pointer mt-2 d-inline-block  btn-cmptl-form position-relative"
        @click="next"
      >
        Davam et
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    form: {
      carNumberquery: "",
      certificateNumber: "",
    },
  }),
  methods: {
    next() {
      this.$router.push({
        name: "ins-cmtpl",
        query: { ...this.form },
      });
    },
  },
};
</script>