<template>
  <section id="home-banner">
    <div class="container ">
      <div class="row justify-content-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
          <p class="home-banner-content h1 text-uppercase">
            BİZİMLƏ <br/> <span class="text-blue">DAHA ARXAYIN</span>.
          </p>
          <p class="banner-text">
            Sığortanızı onlayn şəkildə rahat əldə edin və doğru zamanda ehtiyacınız olan dəstəyi alın.
          </p>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <img src="@/assets/images/hero.png" class="banner-img" alt="Hero" />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 banner-tab">
        <div class="col-xs-12 col-sm-10 col-md-10 col-lg-6 col-xl-4">
          <ul class="nav nav-pills nav-fill">
            <li class="nav-item pe-2 banner-nav-tabs-item mt-1">
              <a :class="{ active: isActive }" class="
                          nav-link
                          active
                          banner-nav-tabs-link
                          small
                          rounded
                        " data-bs-toggle="pill" href="#avto">Avto icbari </a>
            </li>
            <li class="nav-item pe-2 banner-nav-tabs-item mt-1">
              <a class="
                          nav-link
                          banner-nav-tabs-link
                          small
                          rounded
                        " data-bs-toggle="pill" href="#insurance">Səfər sığortası</a>
            </li>
          </ul>
        </div>
        <div class="tab-content col-xs-12 col-sm-12 col-md-12">
          <div id="avto" class="tab-pane active banner-pane-tab  p-4 mb-1 ">
            <cmtpl-initial-form />
            <div class="mt-4">
              <a href="#" class="btn-show-all text-main font-size-default text-decoration-none"
                @click.prevent="showActiveContracts"
              >
                <span class="form-ins-show">Yenilənməli sığortalarımı göstər</span>
                <img class="d-inline-block ms-2" src="@/assets/images/arrowBlue.svg">
              </a>
            </div>
          </div>
          <div id="insurance" class="tab-pane banner-pane-tab  p-4 mb-1">
            <travel-initial-form/>
            <div class="mt-4">
              <a href="#" class="btn-show-all text-main font-size-default text-decoration-none"
                @click.prevent="showActiveTravelContracts"
              >
                <span class="form-ins-show">Yenilənməli sığortalarımı göstər</span>
                <img class="d-inline-block ms-2" src="@/assets/images/arrowBlue.svg">
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
import {  reactive } from 'vue'
import { useRoute } from 'vue-router'
import CmtplInitialForm from "../cmtpl/InitialForm.vue";

import TravelInitialForm from "../travel/InitialForm.vue";

import authService from "@/services/auth.service";

export default {
  components: { CmtplInitialForm,TravelInitialForm },
  methods: {
    showActiveContracts() {
      if (authService.isAuthenticated()) {
        this.$router.push("/profile/contracts/cmtpl/archive");
      } else {
        this.emitter.emit("showLoginModal");
      }
    },
    showActiveTravelContracts() {
      if (authService.isAuthenticated()) {
        this.$router.push("/profile/contracts/travel/archive");
      } else {
        this.emitter.emit("showLoginModal");
      }
    },
  },
  setup() {
    const route = useRoute()

    const isActive = reactive({
      active: false
    })

    return {
      isActive
    }
  }
}
</script>